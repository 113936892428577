.margin{
    margin-left:5rem;
    margin-right:5rem;
}

*{
  list-style: none;
  
}

.result-title{
  font-size:1.5rem;
  margin-left:25%;
  margin-top:1rem;
  margin-bottom: 0 !important;
}

.button{
  width: 6rem;
  margin-top: 3rem !important;
  padding:.8rem;
  border-radius: 8px;
  border:0.1px solid rgba(white, 0.2);
  background-color: purple;
  color:white;
  box-shadow: 8px 9px 8px 0 rgba(0,0,0,0.2);
  transition: 0.1s;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    transform: scale(1.005);
  }

}

.pimg{
  background-attachment: fixed;
  background-image:url('/m_UADS.jpg');
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  min-height: 40vh;
  opacity:0.1;

}

