.payment{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:7rem;
 
    .btn-pay{
        background: mediumorchid;
        color:white;
    }
    .payment-logo{
        width:100vw
        margin 0 auto;

        img{
            width:30vw;
            height:30vw;
            border-radius: 50%;
        }
    }
}