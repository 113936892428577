.jumbotron {
    background-size: cover;
    margin-bottom: 0 !important;
    overflow: hidden;
    height: calc(100vh - 35px);
    background-position: 50% 50%;
      
   
  
    .jumbo-title{
       margin:0 auto;
       text-shadow: 0px 7px 4px rgba(0, 0, 0, 0.5);
      font-weight: 500;
      font-family: Roboto;
      font-weight: 500;
      font-size:5rem;
      margin-bottom: 1rem;
      padding-top: 20rem;
      overflow: hidden;
      color:rgb(218, 217, 212);
      z-index: 3;
        
    }
  
    .jumbo-text{
        color:white;
        font-size: 1.2rem;
        max-width: 60%;
        margin:0 auto;
        overflow: hidden !important;
    }
  }
  