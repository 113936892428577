


.footer {
    position: relative;
    margin-top: 45px;
    padding-top: 90px;
    background: #3e003f;
}

.footer .footer-contact,
.footer .footer-link,
.footer .footer-newsletter {
    position: relative;
    margin-bottom: 45px;
    color: #ffffff;
}

.footer .footer-contact h2,
.footer .footer-link h2,
.footer .footer-newsletter h2 {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 10px;
    font-size: 22px;
    font-weight: 300;
    letter-spacing: 1px;
    color: #ffffff;
}

.footer .footer-contact h2::after,
.footer .footer-link h2::after,
.footer .footer-newsletter h2::after {
    position: absolute;
    content: "";
    width: 45px;
    height: 2px;
    bottom: 0;
    left: 0;
    background: #0796fe;
}

.footer .footer-link a {
    display: block;
    margin-bottom: 10px;
    color: #ffffff;
    transition: .3s;
}

.footer .footer-link a::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.footer .footer-link a:hover {
    color: #0796fe;
    letter-spacing: 1px;
}

.footer .footer-contact p i {
    width: 25px;
}

.footer .footer-social {
    position: relative;
    margin-top: 20px;
}

.footer .footer-social a {
    display: inline-block;
}

.footer .footer-social a i {
    margin-right: 15px;
    font-size: 18px;
    color: #3e003f;
}

.footer .footer-social a:last-child i {
    margin: 0;
}


#logo {
    float: left;
    color:white;
    height:3.5rem;
    width:3.5rem;
  }
  
   #logo h1 {
    font-size: 20px;
    margin: 0;
    padding: 6px 0;
    line-height: 1;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  
   #logo h1 a, #header #logo h1 a:hover {
    color: #fff;
  }
  
   #logo img {
    padding: 0;
    margin: 0;
  }
  
  @media (max-width: 768px) {
     #logo h1 {
      font-size: 26px;
    }
     #logo img {
      max-height: 40px;
    }
  }
  
 