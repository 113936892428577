/*******************************/
/********* General CSS *********/
/*******************************/
body {
  color: #797979;
  background: #ffffff;
  font-family: 'Lato', sans-serif;
}

h1,
h2, 
h3, 
h4,
h5, 
h6 {
  color: #3e003f;
  font-family: 'Oswald', sans-serif;
}

a {
  color: #454545;
  transition: .3s;
}

a:hover,
a:active,
a:focus {
  color: #3e003f;
  outline: none;
  text-decoration: none;
}

.btn:focus,
.form-control:focus {
  box-shadow: none;
}

.container-fluid {
  max-width: 1366px;
}

.back-to-top {
  position: fixed;
  display: none;
  background: #0796fe;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 22px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 9;
}

.back-to-top i {
  color: #3e003f;
  padding-top: 10px;
}

.back-to-top:hover {
  background: #3e003f;
}

.back-to-top:hover i {
  color: #0796fe;
}

@media (min-width: 992px) {
  .mt-125 {
      margin-top: 125px;
  }
}


/**********************************/
/********** Top Bar CSS ***********/
/**********************************/
.top-bar {
  position: relative;
  height: 35px;
  background-image: linear-gradient(to right, #69339c, #3e003f);
}

.top-bar .top-bar-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.top-bar .top-bar-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.top-bar .text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 35px;
  padding: 0 10px;
  text-align: center;
  border-left: 1px solid rgba(255, 255, 255, .15);
}

.top-bar .text:last-child {
  border-right: 1px solid rgba(255, 255, 255, .15);
}

.top-bar .text i {
  font-size: 14px;
  color: #ffffff;
  margin-right: 5px;
}

.top-bar .text h2 {
  color: #eeeeee;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  margin: 0;
}

.top-bar .text p {
  color: #eeeeee;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 0 5px;
}

.top-bar .social {
  display: flex;
  height: 35px;
  font-size: 0;
  justify-content: flex-end;
}

.top-bar .social a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 100%;
  font-size: 16px;
  color: #ffffff;
  border-right: 1px solid rgba(255, 255, 255, .15);
}

.top-bar .social a:first-child {
  border-left: 1px solid rgba(255, 255, 255, .15);
}

.top-bar .social a:hover {
  color: #3e003f;
  background: #ffffff;
}

@media (min-width: 992px) {
  .top-bar {
      padding: 0 60px;
  }
}


/**********************************/
/*********** Nav Bar CSS **********/
/**********************************/
.navbar {
  position: relative;
  transition: .5s;
  z-index: 999;
}

.navbar.nav-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .3) !important;
  transition: .5s;
}

.navbar .navbar-brand {
  margin: 0;
  color: #ffffff;
  font-size: 45px;
  line-height: 0px;
  font-weight: 200;
  text-transform: uppercase;
}

.navbar .navbar-brand img {
  max-width: 100%;
  max-height: 40px;
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  padding: 10px 10px 8px 10px;
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: #0796fe;
  transition: none;
}

.navbar .dropdown-menu {
  margin-top: 0;
  border: 0;
  border-radius: 0;
  background: #f8f9fa;
}

@media (min-width: 992px) {
  .navbar {
      position: absolute;
      width: 100%;
      top: 35px;
      padding: 20px 60px;
      background: rgba(62, 0, 63, .9)!important;
      z-index: 9;
  }
  
  .navbar.nav-sticky {
      padding: 10px 60px;
      background: #3e003f !important;
  }
  
  .page .navbar {
      background: #3e003f !important;
  }
  
  .navbar a.nav-link {
      padding: 8px 15px;
      font-size: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
  }
}

@media (max-width: 991.98px) {   
  .navbar {
      padding: 15px;
      background: #3e003f !important;
  }
  
  .navbar a.nav-link {
      padding: 5px;
  }
  
  .navbar .dropdown-menu {
      box-shadow: none;
  }
}

.carousel {
  position: relative;
  width: 100%;
  height: calc(100vh - 35px);
  min-height: 400px;
  background: #3e003f;
  margin-bottom: 45px;
}

.carousel .container-fluid {
  padding: 0;
}

.carousel .carousel-item {
  position: relative;
  width: 100%;
  height: calc(100vh - 35px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.carousel .carousel-img {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: right;
  overflow: hidden;
}

.carousel .carousel-img::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(9, 42, 73, .5);
  z-index: 1;
}

.carousel .carousel-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel .carousel-text {
  position: absolute;
  max-width: 700px;
  height: calc(100vh - 35px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
}

.carousel .carousel-text h1 {
  text-align: center;
  color: #ffffff;
  font-size: 60px;
  font-weight: 200;
  margin-bottom: 20px;
}

.carousel .carousel-text p {
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  margin-bottom: 25px;
}

.carousel .carousel-text .btn {
  padding: 15px 30px;
  font-size: 16px;
  letter-spacing: 1px;
  color: #ffffff;
  background: none;
  border: 2px solid #ffffff;
  border-radius: 0;
  transition: .3s;
}

.carousel .carousel-text .btn i {
  margin-right: 5px;
}

.carousel .carousel-text .btn:hover {
  color: #0796fe;
  background: #ffffff;
}

.carousel .carousel-btn .btn:first-child {
  margin-right: 15px;
}

.carousel .owl-nav {
  position: absolute;
  width: 122px;
  right: 2px;
  bottom: 2px;
  display: flex;
  z-index: 9;
}

.carousel .owl-nav .owl-prev,
.carousel .owl-nav .owl-next {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0796fe;
  background: #3e003f;
  font-size: 22px;
  transition: .3s;
}

.carousel .owl-nav .owl-prev:hover,
.carousel .owl-nav .owl-next:hover {
  color: #ffffff;
  background: #0796fe;
}

.carousel .owl-nav .owl-prev {
  margin-right: 2px;
}

.carousel .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

#videoModal .modal-dialog {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0 auto;
}

#videoModal .modal-body {
  position: relative;
  padding: 0px;
}

#videoModal .close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: -30px;
  z-index: 999;
  font-size: 30px;
  font-weight: normal;
  color: #ffffff;
  background: #000000;
  opacity: 1;
}

@media (max-width: 991.98px) {
  .carousel,
  .carousel .carousel-item,
  .carousel .carousel-text {
      height: calc(100vh - 105px);
  }
  
  .carousel .carousel-text h1 {
      font-size: 35px;
  }
  
  .carousel .carousel-text p {
      font-size: 16px;
  }
  
  .carousel .carousel-text .btn {
      padding: 12px 30px;
      font-size: 15px;
      letter-spacing: 0;
  }
}

@media (max-width: 767.98px) {
  .carousel,
  .carousel .carousel-item,
  .carousel .carousel-text {
      height: calc(100vh - 70px);
  }
  
  .carousel .carousel-text h1 {
      font-size: 30px;
  }
  
  .carousel .carousel-text .btn {
      padding: 10px 25px;
      font-size: 15px;
      letter-spacing: 0;
  }
}

@media (max-width: 575.98px) {
  .carousel .carousel-text h1 {
      font-size: 25px;
  }
  
  .carousel .carousel-text .btn {
      padding: 8px 20px;
      font-size: 14px;
      letter-spacing: 0;
  }
}

.about {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.about .section-header {
  margin-bottom: 30px;
  margin-left: 0;
}

.about .about-img {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: .5s;
}

.about .about-img::after {
  position: absolute;
  content: "";
  top: 60px;
  right: 60px;
  bottom: 60px;
  left: 60px;
  background: transparent;
  border: 30px solid;
  border-image: repeating-linear-gradient( 45deg, rgba(0,0,0,.05), rgba(0,0,0,.05) .5%, rgba(0,0,0,.1) .5%, rgba(0,0,0,.1) 1%) 30;
  z-index: 1;
}

.about .about-img:hover::after {
  border-image: repeating-linear-gradient( 45deg, rgba(0,0,0,.1), rgba(0,0,0,.1) .5%, rgba(0,0,0,.05) .5%, rgba(0,0,0,.05) 1%) 30;
}

.about .about-img-1 {
  position: relative;
  height: 100%;
  height: 100%;
  margin: 0 150px 150px 0;
}

.about .about-img-2 {
  position: absolute;
  height: 100%;
  height: 100%;
  top: 150px;
  left: 150px;
  z-index: 1;
}

.about .about-img-1 img,
.about .about-img-2 img {
  position: relative;
  width: 100%;
}

.about .about-text p {
  font-size: 16px;
}

.about .about-text a.btn {
  position: relative;
  margin-top: 15px;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #3e003f;
  border: 2px solid #3e003f;
  border-radius: 0;
  background: none;
  transition: .3s;
}

.about .about-text a.btn:hover {
  color: #0796fe;
  background: #3e003f;
}

@media (max-width: 767.98px) {
  .about .about-img {
      margin-bottom: 30px;
      height: auto;
  }
}




.blog {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.blog .section-header {
  margin-bottom: 75px;
}

.blog .blog-carousel {
  position: relative;
  width: calc(100% + 30px);
  left: -15px;
  right: -15px;
}

.blog .blog-item {
  position: relative;
  margin: 0 15px;
}

.blog.blog-page .blog-item {
  margin: -30px 0 0 0;
}

.blog .blog-img {
  position: relative;
  width: 100%;
  z-index: 1;
}

.blog .blog-img img {
  width: 80%;
}

.blog .blog-content {
  position: relative;
  width: calc(100% - 60px);
  top: -60px;
  left: 30px;
  padding: 25px 30px;
  background: #ffffff;
  box-shadow: 0 0 30px rgba(0, 0, 0, .1);
  z-index: 2;
}

.blog .blog-content h2.blog-title {
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 1px;
}

.blog .blog-meta {
  position: relative;
  margin-bottom: 15px;
}

.blog .blog-meta * {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 300;
  color: #797979;
}

.blog .blog-meta i {
  color: #0796fe;
  margin: 0 2px 0 10px;
}

.blog .blog-meta i:first-child {
  margin-left: 0;
}

.blog .blog-text {
  position: relative;
}

.blog .blog-text p {
  margin-bottom: 10px;
}

.blog .blog-item a.btn {
  position: relative;
  padding: 0 0 0 35px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #0796fe;
  transition: .3s;
}

.blog .blog-item a.btn:hover {
  color: #3e003f;
}

.blog .blog-item a.btn::before {
  position: absolute;
  content: "";
  width: 30px;
  height: 2px;
  top: 10px;
  left: 0;
  background: #0796fe;
}

.blog .owl-nav {
  position: relative;
  width: 100%;
  margin-top: -30px;
  display: flex;
  justify-content: center;
}

.blog .owl-nav .owl-prev,
.blog .owl-nav .owl-next {
  position: relative;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0796fe;
  font-size: 25px;
  transition: .3s;
}

.blog .owl-nav .owl-prev {
  padding-right: 80px;
}

.blog .owl-nav .owl-next {
  padding-left: 80px;
}

.blog .owl-nav .owl-prev:hover,
.blog .owl-nav .owl-next:hover {
  color: #3e003f;
}

.blog .owl-nav .owl-prev::after,
.blog .owl-nav .owl-next::after {
  position: absolute;
  content: "";
  width: 80px;
  height: 3px;
  top: 11px;
  background: #0796fe;
  transition: .3s;
}

.blog .owl-nav .owl-prev::after {
  left: 9px;
}

.blog .owl-nav .owl-next::after {
  right: 9px;
}

.blog .owl-nav .owl-prev:hover::after {
  background: #3e003f;
}

.blog .owl-nav .owl-next:hover::after {
  background: #3e003f;
}

.blog .pagination {
  margin-bottom: 0;
}

.blog .pagination .page-link {
  margin-top: -30px;
  color: #3e003f;
  border-radius: 0;
  border-color: #3e003f;
}

.blog .pagination .page-link:hover,
.blog .pagination .page-item.active .page-link {
  color: #0796fe;
  background: #3e003f;
}

.blog .pagination .disabled .page-link {
  color: #999999;
}
