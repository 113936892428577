.about {
    position: relative;
    width: 100%;
    padding: 45px 0;
}

.about .section-header {
    margin-bottom: 30px;
    margin-left: 0;
}

.about .about-img {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: .5s;
}

.about .about-img::after {
    position: absolute;
    content: "";
    top: 60px;
    right: 60px;
    bottom: 60px;
    left: 60px;
    background: transparent;
    border: 30px solid;
    border-image: repeating-linear-gradient( 45deg, rgba(0,0,0,.05), rgba(0,0,0,.05) .5%, rgba(0,0,0,.1) .5%, rgba(0,0,0,.1) 1%) 30;
    z-index: 1;
}

.about .about-img:hover::after {
    border-image: repeating-linear-gradient( 45deg, rgba(0,0,0,.1), rgba(0,0,0,.1) .5%, rgba(0,0,0,.05) .5%, rgba(0,0,0,.05) 1%) 30;
}

.about .about-img-1 {
    position: relative;
    height: 100%;
    height: 100%;
    margin: 0 150px 150px 0;
}

.about .about-img-2 {
    position: absolute;
    height: 100%;
    height: 100%;
    top: 150px;
    left: 150px;
    z-index: 1;
}

.about .about-img-1 img,
.about .about-img-2 img {
    position: relative;
    width: 100%;
}

.about .about-text p {
    font-size: 16px;
}

.about .about-text a.btn {
    position: relative;
    margin-top: 15px;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #3e003f;
    border: 2px solid #3e003f;
    border-radius: 0;
    background: none;
    transition: .3s;
}

.about .about-text a.btn:hover {
    color: #0796fe;
    background: #3e003f;
}

@media (max-width: 767.98px) {
    .about .about-img {
        margin-bottom: 30px;
        height: auto;
    }
}


.home{
margin-top: 5rem;
}