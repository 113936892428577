.card-container {
    display: flex;
  flex-wrap: wrap;
  justify-content: center;
    text-align: center;
    background-color: #f5f5f5;
    font-family: 'Baloo Paaji 2', cursive;
  }
  
  .hidden{
    display:none !important;
  }
  .card {
    background-color: rgb(54, 53, 53);
    height:60vh;
    max-width: 28vw;
    @media screen and (max-width: 480px) {
      max-width:80vw !important;
      height:50vh;
       }
    flex: 0 0 calc(16.66% - 20px);
    border-radius: 5px;
    margin: 2rem;
    display: flex;
    visibility: visible;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.7);
    color: white;
  }
  
  .card__name {
    margin-top: 15px;
    font-size: 1.1em;
  }
  
  .card__image {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    border: 5px solid #272133;
    margin-top: 20px;
    box-shadow: 0 10px 50px rgba(235, 25, 110, 1);
  }
  .post{
    font-size: 1.3rem;
  }
 
  
  .draw-border {
    margin-bottom: 1rem !important;
    box-shadow: inset 0 0 0 4px #58cdd1;
    color: #58afd1;
    -webkit-transition: color 0.25s 0.0833333333s;
    transition: color 0.25s 0.0833333333s;
    position: relative;
  }
  
  .draw-border::before,
  .draw-border::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0rem;
    height: 0;
    bottom: 0;
    right: 0;
  }
  
  .draw-border::before {
    border-bottom-width: 4px;
    border-left-width: 4px;
  }
  
  .draw-border::after {
    border-top-width: 4px;
    border-right-width: 4px;
  }
  
  .draw-border:hover {
    color: black;
  }
  
  .draw-border:hover::before,
  .draw-border:hover::after {
    border-color: #eb196e;
    -webkit-transition: border-color 0s, width 0.25s, height 0.25s;
    transition: border-color 0s, width 0.25s, height 0.25s;
    width: 100%;
    height: 100%;
  }
  
  .draw-border:hover::before {
    -webkit-transition-delay: 0s, 0s, 0.25s;
    transition-delay: 0s, 0s, 0.25s;
  }
  
  .draw-border:hover::after {
    -webkit-transition-delay: 0s, 0.25s, 0s;
    transition-delay: 0s, 0.25s, 0s;
  }
  
  .btn {
    background: none;
    border: none;
    cursor: pointer;
    line-height: 1.5;
    font: 700 1rem 'Roboto Slab', sans-serif;
    padding: 0.75em 2em;
    letter-spacing: 0.05rem;
    margin: 1em;
    width: 13rem;
  }
  
  .btn:focus {
    outline: 2px dotted #55d7dc;
  }
  
  
  
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    font-size: 1.2em;
  }
  
  .votes-container{
    background-color: #f5f5f5;
    display:flex;
    flex-direction: column;
  }

  .btn-submit{
    background: purple;
    border: none;
    cursor: pointer;
    line-height: 1.5;
    font: 700 1rem 'Roboto Slab', sans-serif;
    padding: 0.75em 2em;
    letter-spacing: 0.05rem;
    margin: 1em;
    width: 13rem;
  
  }

  .centre{
    margin:0 auto;
    @media screen and (max-width: 780px) {
      font-size: 0.9rem;
    }
  }

  