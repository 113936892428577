.voting-directory{
    background-color: #464c51;
    display:flex;
    align-items: center;
    height:80vh;
    flex-direction: column !important;
}

.head-text{
 text-align: center;
 margin-top:2rem;
 color:white;
 font-family:"Open Sans", HelveticaNeue-Light, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif ;
font-size:1.3rem;
line-height: 40px;
}

.directory{
    display:grid;
    grid-template-columns: 1fr 1fr;
    justify-self: center;
    align-items: center;
    margin-bottom: 5rem;
    .item{
        margin-left:10%;
        margin-bottom:10%;
        min-width:20vw;
        text-align: center ;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
font-size:1.2rem;
line-height: 60px;
        border-radius: 8px;
        height:10vh;
        box-shadow: -7px 1px 20px rgba(0, 0, 0, 0.5);
        @media screen and (max-width: 780px) {
            width:43vw !important;
            font-size:1rem;
            margin-left:5%;
            height: 10vh;
            text-align: center;
            margin-right:3%;
            line-height: 2.5;
    
        }
    }
}