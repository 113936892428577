.about-tex{
margin:5rem 15rem 3rem 15rem;
text-align: left;
font-family:"Open Sans", HelveticaNeue-Light, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif ;
font-size:18px;
line-height: 30px;
font-weight: 400;
color:rgb(57, 66, 74);
@media screen and (max-width: 780px) {
  margin:2rem;
 font-size: 16px;
}
.bold-text{
font-size: 30px;
margin-left:auto;
margin-right:auto;
text-align: center;
line-height:3rem;
@media screen and (max-width: 780px) {
  font-size: 23px;
  
}
}
}
.carousel-div{
height:100vh;
width:100vw;
background:grey
}


.pagination {
    margin-top:2rem;
    margin-bottom: 2rem;
  display: flex;
  max-width: 60vw;
  min-width: 20rem;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  @media screen and (max-width: 780px) {
    max-width:100vw;


    
     }
}


.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #6c7ac9;
  color: #6c7ac9;
  @media screen and (max-width: 780px) {
   padding:5px;

    
     }
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  background: #6c7ac9;
}

.pagination__link--disabled a {
  color: white;
  border: 1px solid white;
}


.user-name{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-left:2rem !important;
}

.page-list{
    margin-top: 2rem;
    margin-left:2rem;
}