.profile-page{
    margin:0 auto;
    font-family:"Open Sans", HelveticaNeue-Light, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif ;
    font-size:22px;
line-height: 30px;
.info-card{
 font-size: 1rem !important;
 width: 40vw !important;
 height: 60vh;
 @media screen and (max-width: 780px) {
    margin-top: 2rem;
    height:75vh;
    width:90vw !important;
    
}
}

.user-card{
   width:40vw !important;
   height: 60vh;
   @media screen and (max-width: 780px) {
        margin-top: 2rem;
        height:35vh;
        width:90vw !important;
        margin-bottom: 2rem;
   }
}
  
.onboard{
    font-family:"Open Sans", HelveticaNeue-Light, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif ;
    font-size:2rem !important;
    line-height: 40px;
    width:80%;
    color: white;
    background-color: purple;
    padding:2rem;
    margin:1rem  0 2rem 1rem;
    border-radius: 8px;
    box-shadow: -10px 10px 15px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 780px) {
        flex-direction: column;
        margin:1rem 0 1rem 1rem;
        height:10vh;
        padding:6vw 0 0 2rem;
        width:90%;
        font-size: 18px !important;
        //text-align: center;
        
       }
}
}

.profile-container{
    display:flex;
   // justify-content: space-between;
   @media screen and (max-width: 780px) {
    flex-direction: column;
   }
}
.profile-img{
    width:14vw;
    height:13vw;
    padding: 5px;
    border-radius: 4px;
    margin:0.8rem auto;
    border: 0.5px grey solid;
}

.profile-details{
   
width:25vw;
height:65vh;
display:flex;
text-align: left;
margin-left:4vw;
padding-left: 1vw;
padding-right: 1vw;
overflow: hidden;
flex-direction: column;
background-color: whitesmoke;
border-radius: 8px;
margin-bottom: 34px;

@media screen and (max-width: 780px) {
  width:70%;
  height:40vh;
  margin: 0 auto;
   }
h6{
margin: 5px !important;
}
hr{
    width:100%;
    color:grey;
    background-color: grey;
}

box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
transition: 0.3s;


/* On mouse-over, add a deeper shadow */
&:hover {
box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
}

.chart-container{
    width:40vw;
    height:60vh;
    @media screen and (max-width: 780px) {
        width:60vw !important;
        height:60vh !important;
        margin: 0 auto;
         }
}
.btn-cont{
    display:flex;
}
.btn-container-vote{
align-items: center;
justify-content: center;
margin-left: 40% ;
margin-bottom:3rem;
@media screen and (max-width: 780px) {
    margin-left: 15%;

    
     }
 
}
.loading{
    min-height: 80vh;
}
.bt{
    color:white !important;
}


.button-page{
    font-size: 1.2rem;
    margin-top:2rem;
    font: 700 1rem 'Roboto Slab', sans-serif;
    padding: 0.75em 2em;
    border: none;
    cursor: pointer;
    margin-right: 1rem;
    letter-spacing: 0.05rem;
    //border-radius: 9px;
    background-color: purple;
    color:white !important;
    line-height: 1.5;
}